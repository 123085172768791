import React, { useRef } from "react";
import { Flex, Image, Text } from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { ILeadAttributes } from "../../../../../types/ILead";
import { PIN_MAP } from "../../../../../pages/Dashboard/Views/MapView/Icons";
import ImgPlaceholder from "../../../../../assets/images/img-placeholder.png";

import "./GlobalSearchResult.scss";

const DATE_FORMAT = "MMM D, YYYY";

export interface IGlobalSearchResultProps {
  closeDropdown: () => void;
  lead: ILeadAttributes;
  searchText: string;
}

export const GlobalSearchResult: React.FC<IGlobalSearchResultProps> = ({
  closeDropdown,
  lead,
  searchText,
}) => {
  const { t } = useTranslation(["common"]);

  const tileRef = useRef<HTMLAnchorElement>(null);

  const {
    actionedAt, address, id, identifier, primaryPhotoUrl, status,
  } = lead;

  const renderSearchTextMatches = (text: string, pattern: string) => {
    if (pattern.trim() === "") {
      return <Text type="P">{text}</Text>;
    }

    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedPattern})`, "gi");

    const parts = text.split(regex);

    return (
      <Text type="P">
        {parts.map((part, index) => (regex.test(part)
          ? <span key={parts.slice(0, index).join("-")} className="match">{part}</span>
          : part))}
      </Text>
    );
  };

  const renderName = () => renderSearchTextMatches(identifier, searchText);

  const renderPinAndAddress = () => (
    <Flex className="Details__Address" align="center" gap={4}>
      {PIN_MAP[status]}
      {address
        ? renderSearchTextMatches(address, searchText)
        : <Text type="P">{t("navigation.globalSearch.noAddress")}</Text>}
    </Flex>
  );

  const renderUpdatedAt = () => (
    <Text type="S" weight="Light">
      {t("navigation.globalSearch.updatedAt", {
        date: moment(actionedAt).format(DATE_FORMAT),
      })}
    </Text>
  );

  return (
    <Link ref={tileRef} to={`/leads/${id}`} onClick={closeDropdown}>
      <Flex className="GlobalSearchResult" data-testid="GlobalSearchResult" gap={8}>
        <Image
          data-testid="GlobalSearchResult__Thumbnail"
          className="GlobalSearchResult__Thumbnail"
          src={primaryPhotoUrl || ImgPlaceholder}
          width={80}
          height={60}
        />
        <Flex className="GlobalSearchResult__Details" justify="space-between" vertical>
          {renderName()}
          {renderPinAndAddress()}
          {renderUpdatedAt()}
        </Flex>
      </Flex>
    </Link>
  );
};

export default GlobalSearchResult;
